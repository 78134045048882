<template>
    <div>
        <b-row>
            <b-col cols="7">
                <levels-list />
            </b-col>
            <b-col cols="5">
                <locations-list />
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { BCol, BRow } from 'bootstrap-vue'
import LevelsList from '../levels/levels-list/LevelsList.vue';
import LocationsList from '../locations/locations-list/LocationsList.vue';

export default {
    components: {
        BCol,
        BRow,
        LevelsList,
        LocationsList,
    },
    data() {
        return {

        }
    }
}
</script>

import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLocations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/locations', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLocation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/locations/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editLocation(ctx, { id, name }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/locations/${id}`, { 'name': name })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addLocation(ctx, locationData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/locations', locationData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
